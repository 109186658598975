<template>

  <trazo-off-canvas
    :isOpen="isOpen"
    id="modal-form-measure-unit"
    :selectorToggle="selectorToggle"
    v-on:close="closeModal"
    :title="textModal"
  >
    <template v-slot:header-buttons>
      <b-button size="sm" variant="light" @click="closeModal">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>
      <b-button size="sm" variant="light" @click="onSubmit">
        {{ sending ? "Guardando..." : "Guardar" }}
      </b-button>
    </template>

    <b-row class="justify-content-center">
    
      <form class="full" id="formSubproject" ref="formSubproject">

        <b-alert
          show
          variant="danger"
          v-bind:key="error"
          v-for="error in errors"
          >{{ error }}</b-alert
        >

        <trazo-form-input
          id="input-code-nav"
          v-model="codeNav"
          name="codeNav"
          label="Código Nav"
          :required="true"
          labelPosition="top"
          type="text"
        />

        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Tipos de Unidad de Medida"
        >
          <div
            :class="{
              invalid: isInvalidSelectNull(tipoUnidadMedidaSelected)
            }"
          >
            <a-multiselect
              name="tipo_unidad_medida"
              track-by="id"
              label="label"
              v-model="tipoUnidadMedidaSelected"
              :multiple="false"
              :hide-selected="true"
              placeholder="Seleccione"
              :options="tipoUnidadMedidaOptions"
              :allow-empty="false"
              @select="onSelect($event, 'tipo_unidad_medida')"
            />
            <input
              type="hidden"
              name="tipo_unidad_medida_hidden"
              v-model="tipo_unidad_medida_hidden"
            />
          </div>

        </trazo-form-label-slot>

        <trazo-form-input
          id="input-code"
          v-model="code"
          name="code"
          label="Código"
          :required="true"
          labelPosition="top"
          type="text"
        />

        <trazo-form-input
          id="input-descripcion"
          v-model="descripcion"
          name="descripcion"
          label="Descripción"
          :required="true"
          labelPosition="top"
          type="text"
        />

        <trazo-form-input
          id="input-precision"
          type="text"
          labelPosition="top"
          label="Precisión"
          v-model="precision"
          name="precision"
          :required="true"
          autocomplete="off"
        />

        <b-form-checkbox v-model="is_active" value="1" unchecked-value="0">
          Está activo
        </b-form-checkbox>

      </form>

    </b-row>

  </trazo-off-canvas>

</template>

<script>

import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoOffCanvas from "@/view/content/TrazoOffCanvas";
import "vue-multiselect/dist/vue-multiselect.min.css";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";

function initialState() {
  return {
    textModal: "NUEVA UNIDAD DE MEDIDA",
    id: "",
    codeNav: "",
    user_roles: [],
    code: "",
    descripcion: "",
    precision: "",
    is_active: 1,
    tipoUnidadMedidaSelected: null,
    tipo_unidad_medida_hidden: "",
    isTouchedForm: false,
    edit: false,
    errors: [],
    sending: false
  };
}

export default Vue.component("modal-form-measure-unit", {
  data() {
    return initialState();
  },
  props: ["objForm", "tipoUnidadMedidaOptions","isOpen", "selectorToggle"],
  watch: {
    objForm(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        this.setData();
      }
    },
    tipoUnidadMedidaOptions(val){
      console.log("tipoUnidadMedidaOptions=>",val);
    }
  },
  methods: {
    /* validar(){
      let tmp = this.precision.trim();
      console.log("tmp=>",tmp);
      if(tmp){
        tmp = tmp.substring(0,1);
        console.log(Number(tmp));
        if(Number(tmp)){
          if( Number(tmp)>0 && Number(tmp)<5 ){
            this.validado = tmp;
            console.log("validado=>",this.validado);
            return 
          }
        }
      }
      this.validado = "";
      return 
    },  */
    
    closeModal() {
      this.resetForm();
      Object.assign(this.$data, initialState());
      this.$emit("close");
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {});
      this.fv.off("core.form.invalid", () => {});
      this.fv.resetForm();
    },
    isInvalidSelectNull(selectedItems) {
      return this.isTouchedForm && selectedItems === null;
    },
    onSelect(value, itemName) {
      this.$data[itemName] = value;
      if ("id" in value) {
        this.$data[`${itemName}_hidden`] = value.id;
      }
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        const saveFunction = this.id ? ApiService.patch : ApiService.post;
        this.errors = [];
        this.sending = true;
        saveFunction(`api/measure-unit/${this.id ? this.id + "/" : ""}`, {
          abbrev: this.codeNav.trim(),
          code: this.code.trim(),
          description: this.descripcion.trim(),
          precision: Number(this.precision) ? Number(this.precision) : '' ,
          type: this.tipo_unidad_medida_hidden,
          is_active: this.is_active===1 ? true:false,
        })
          .then(({ data }) => {
            this.$emit("toast", {
              message: `Se ha ${
                this.edit ? "editado" : "creado"
              } el item correctamente`,
              object: {
                title: `Éxito`,
                variant: "success",
                solid: true
              }
            });
            this.$emit(this.edit ? "updated" : "created", data);
            this.sending = false;
            this.closeModal();
          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            Object.entries(data).forEach((object) => {
              this.errors.push(object[1].join(" - "));
              this.fv.updateFieldStatus(object[0], "Invalid");
            });
            this.$emit("toast", {
              message: "Ha ocurrido un error",
              object: {
                title: `Error`,
                variant: "danger",
                solid: true
              }
            });
          });
      });
      this.fv.on("core.form.invalid", () => {
        this.$emit("toast", {
          message: "Por favor complete los campos correctamente.",
          object: {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        });
      });
      this.fv.on("core.form.invalid", () => {
        this.isTouchedForm = true;
      });
    },
    setData() {
      if (!this.custom_lodash.isEmpty(this.objForm)) {
        this.id = this.objForm.id;
        this.codeNav = this.objForm.abbrev;
        this.tipoUnidadMedidaSelected = this.tipoUnidadMedidaOptions.filter((el) => el.id === this.objForm.type)[0];
        this.tipo_unidad_medida_hidden = this.objForm.type;
        console.log("dat=>",this.objForm);
        this.code = this.objForm.code;
        this.descripcion = this.objForm.description;
        this.precision = this.objForm.precision.toString() ;
        this.is_active = this.objForm.is_active ? 1:0;
        this.textModal = "EDITAR UNIDAD DE MEDIDA";
        this.edit = true;
      }
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");
      this.fv = formValidation(formSubproject, {
        fields: {
          codeNav: {
            validators: {
              notEmpty: {
                message: "El Código Nav es obligatorio"
              }
            }
          },
          code: {
            validators: {
              notEmpty: {
                message: "El Código es obligatorio"
              }
            }
          },
          descripcion: {
            validators: {
              notEmpty: {
                message: "La Descripción es obligatoria"
              }
            }
          },
          precision: {
            validators: {
              notEmpty: {
                message: "La Precisión es obligatoria"
              },
              digits: {
                message: 'El valor no es un dato válido'
              },
            }
          },
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    }
  },
  mounted() {
    this.setData();
    this.setupValidation();
  },
  components: {
    TrazoFormInput,
    TrazoOffCanvas,
    TrazoFormLabelSlot
  }
});
</script>
